.grad {
    background: linear-gradient(360deg, #610246 10%, #232554 90%);
    /* background: radial-gradient(to right, #c4d7e6 0, #c4d7e6 33%, #66a5ad 33%, #66a5ad 66%, #ff0000 66%, #ff0000 100%); */
}

.partners {
    filter: brightness(0) invert(1);
}

.partners:hover {
    filter: brightness(1) invert(0);
    transition: 0.3s;
}