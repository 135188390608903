@font-face {
    font-family: 'Heading';
    src: local('Heading'), url('../../../fonts/EvilEmpire-4BBVK.ttf') format('truetype');
}

.bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #1D1D34;
}
.lower-half {
    position: absolute;
    width: 100%;
    height:50%;
    bottom: 0%;
    background: linear-gradient(270deg, #D9D9D9 -6.82%, rgba(186, 185, 255, 0.975849) -4.47%, rgba(247, 61, 61, 0) 90.23%);
    mix-blend-mode: color-dodge;
    opacity: 0.3;
}
.from-right{
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(217, 217, 217, 0) -6.88%, #D9D9D9 101.1%, rgba(217, 217, 217, 0) 101.11%);
    opacity: 0.5;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.reddish{
    position: absolute;
    top:50%;
    width:100%;
    height: 50%;
    background: linear-gradient(270deg, #D9D9D9 -6.82%, rgba(186, 185, 255, 0.975849) -4.47%, rgba(247, 61, 61, 0) 90.23%);
    mix-blend-mode: color-dodge;
    opacity: 0.3;
}

.top-70{
    position: absolute;
    width: 100%;
    height: 70%;
    background: linear-gradient(180deg, #080751 34.52%, rgba(53, 51, 200, 0) 88.31%);
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.gameplay-container {
    width: 100vw;
    height: 100vh;
    display: flex;
	flex-direction: row;
	justify-content: space-evenly;
    align-items: center;
}

.gameplay-col {
    display: flex;
	flex-direction: column;
	justify-content: space-evenly;
    align-items: center;
}


.image-hero {
    width: 100%; 
    position: static; 
    text-align: center ;
    height:800px;
}