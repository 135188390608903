#faq:root{
}
.acc{
    --bs-accordion-border-color: none;
}
.acc .accordion-collapse{
    width:calc(100% - 50px);
    margin-left:25px;
    --bs-accordion-border-width: 0px !important;
    --bs-accordion-border-color: none;
}
.radius *{

    --bs-accordion-active-color: white;
    --bs-accordion-btn-bg: none;
    --bs-accordion-btn-icon: url(./plus.svg);
    --bs-accordion-btn-active-icon: url(./plus.svg);
    /*--bs-accordion-border-radius: 100px;*/
    border-radius: 25px !important;
    /*background:#141425;*/
    color: white;
}
.radius .accordion-button{
    /* background-image: linear-gradient(to right,#565f78,#777778) !important; */
    background-image: linear-gradient(to right,#D72343, #258CBE) !important;
}
/* .radius .accordion-button-icon{
    background: white;
} */
.radius-body *{
    width: 50% !important;
    border-bottom-left-radius: 100px !important;
    border-bottom-right-radius: 100px !important;
}
